




















































import { Vue, Component } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class Todo extends Vue {
  modelFieldList: any = [
    { c1: '业务类型', c2: '字符串', c3: 0, c4: '业务类型字典' },
    { c1: '商户名称', c2: '字符串', c3: 0, c4: '' },
    { c1: '合同名称', c2: '字符串', c3: 0, c4: '' },
    { c1: '项目名称', c2: '字符串', c3: 0, c4: '' },
    { c1: '数据日期', c2: '字符串', c3: 0, c4: '' },
    { c1: '产品类型', c2: '字符串', c3: 0, c4: '产品类型' },
    { c1: '风险评级', c2: '字符串', c3: 0, c4: '' }
  ];
}
